<template>
<div class="notwx">
  <van-icon name="warning" size="18px" color="#1989fa" class="txt"/>
  请用微信客户端打开该链接！
</div>
</template>

<script>
export default {
  name: "notwx"
}
</script>

<style scoped>
.notwx{text-align: center;margin-top: 5vh;}
.txt{vertical-align: text-top;}
</style>